import request from '@/utils/request'


// 查询船舶港口区域信息列表
export function listShipPortRegion(query) {
  return request({
    url: '/gather/gather-ship-port-region/list',
    method: 'get',
    params: query
  })
}

// 查询船舶港口区域信息分页
export function pageShipPortRegion(query) {
  return request({
    url: '/gather/gather-ship-port-region/page',
    method: 'get',
    params: query
  })
}

// 查询船舶港口区域信息详细
export function getShipPortRegion(data) {
  return request({
    url: '/gather/gather-ship-port-region/detail',
    method: 'get',
    params: data
  })
}

// 新增船舶港口区域信息
export function addShipPortRegion(data) {
  return request({
    url: '/gather/gather-ship-port-region/add',
    method: 'post',
    data: data
  })
}

// 修改船舶港口区域信息
export function updateShipPortRegion(data) {
  return request({
    url: '/gather/gather-ship-port-region/edit',
    method: 'post',
    data: data
  })
}

// 删除船舶港口区域信息
export function delShipPortRegion(data) {
  return request({
    url: '/gather/gather-ship-port-region/delete',
    method: 'post',
    data: data
  })
}
